<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="kt_modal_add-vivaio"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Inserimento vivaio</h5>

            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body p-6">
              <form autocomplete="off">
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-if="alertCampiObb"
                >
                  Compila i campi obbligatori
                </div>
                <div class="ps-5 pe-5">
                  <div class="row mb-4">
                    <div class="col-md-6">
                      <label class="fw-bold text-gray-600">Persona*</label>
                      <div class="dp__input_wrap">
                        <input
                          placeholder=""
                          name="nome_persona"
                          type="text"
                          class="form-control"
                          @input="
                            focus = 3;
                            getTesserati($event.target.value);
                            nome_persona === ''
                              ? (id_persona = null)
                              : (id_persona = 0);
                          "
                          v-model="nome_persona"
                          autocomplete="off"
                        />
                        <Field
                          name="id_persona"
                          type="hidden"
                          v-model="id_persona"
                          class="form-control"
                        />
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          class="dp__icon dp__clear_icon dp__input_icons"
                          @click="
                            nome_persona = null;
                            id_Persona = null;
                          "
                        >
                          <path
                            d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                          ></path>
                          <path
                            d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                          ></path>
                        </svg>
                      </div>
                      <ListTesserati
                        v-if="focus === 3"
                        :focusOnInput="focus === 3"
                        :list="tesseratiList"
                        @hideList="resetTesseratiList('aaa')"
                        @selectedTesserato="
                          nome_persona = $event.name;
                          id_persona = $event.value;
                          resetTesseratiList($event);
                        "
                      ></ListTesserati>
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_persona"
                      />
                    </div>
                    <div class="col-md-6">
                      <label class="fw-bold text-gray-600">Società*</label>
                      <div class="dp__input_wrap">
                        <input
                          placeholder=""
                          name="nome_societa"
                          type="text"
                          class="form-control"
                          @input="
                            getSocieta($event.target.value);
                            id_societa = 0;
                            nome_societa === ''
                              ? (id_societa = null)
                              : (id_societa = 0);
                            focus = 4;
                          "
                          v-model="nome_societa"
                          autocomplete="off"
                        />
                        <Field
                          name="id_societa"
                          type="hidden"
                          v-model="id_societa"
                          class="form-control"
                        />
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          class="dp__icon dp__clear_icon dp__input_icons"
                          @click="
                            nome_societa = null;
                            id_societa = null;
                          "
                        >
                          <path
                            d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                          ></path>
                          <path
                            d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                          ></path>
                        </svg>
                      </div>
                      <ListSocieta
                        v-if="focus === 4"
                        :focusOnInput="focus === 4"
                        :list="societa"
                        @hideList="resetSocieta"
                        @selectedSocieta="
                          resetSocieta();
                          nome_societa = $event.name;
                          id_societa = $event.value;
                        "
                      ></ListSocieta>
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_societa"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-12">
                      <label class="fw-bold text-gray-600">Stagioni*</label>
                      <Multiselect
                        v-model="selectedStagioni"
                        :options="stagioniOptions"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="false"
                        @input="inputElement"
                      />
                      <Field
                        name="id_stagioni"
                        type="hidden"
                        v-model="id_stagioni"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_stagioni"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModal"
            >
              Chiudi
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Salva
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { Form, ErrorMessage, Field } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import "vue3-date-time-picker/dist/main.css";
import ListTesserati from "@/components/components-fit/utility/ListTesserati.vue";
import ListSocieta from "@/components/components-fit/utility/ListSocieta.vue";
import Multiselect from "@vueform/multiselect";
import { addVivaio } from "../../../../requests/vivaio";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "AddVivaio",
  emits: ["refreshList"],
  components: {
    Loading,
    ListTesserati,
    ListSocieta,
    Form,
    ErrorMessage,
    Field,
    Multiselect,
  },
  setup(props, { emit }) {
    const store = useStore();

    const alertCampiObb = ref(false);

    const nome_persona = ref("");
    const id_persona = ref(null);

    const getTesserati = (string) => {
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneNomeCognome", string);
    };
    const resetTesseratiList = (e) => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const spliceTesserati = () => {
      nome_persona.value = "";
      id_persona.value = 0;
      resetTesseratiList();
    };

    const nome_societa = ref("");
    const id_societa = ref(null);

    const getSocieta = (string) => {
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaNomeCodAff", string);
    };
    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    };
    const spliceSocieta = () => {
      nome_societa.value = "";
      id_societa.value = 0;
      resetSocieta();
    };

    const id_stagioni = ref("");

    const stagioniOptions = computed(() => store.getters.stagioniOptions);

    const selectedStagioni = ref([]);

    const schema = yup.object().shape({
      id_persona: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable()
        .moreThan(0, "Obbligatorio"),
      id_societa: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable()
        .moreThan(0, "Obbligatorio"),
      id_stagioni: yup.string().required("Obbligatorio").nullable(),
    });

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = (values) => {
      disableButton.value = true;
      isLoading.value = true;
      alertCampiObb.value = false;
      const responseAdd = ref({});
      responseAdd.value = addVivaio(values);
      responseAdd.value.then((value) => {
        if (value.status == 200) {
          Swal.fire({
            html: "Vivaio aggiunto con successo.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          resetField();
          document.getElementById("closeModal").click();
          emit("refreshList");
        } else {
          const strMsg = ref(
            value.data.message
              ? value.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          Swal.fire({
            html: strMsg.value,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
        disableButton.value = false;
        isLoading.value = false;
      });
    };

    const onInvalidSubmit = () => {
      alertCampiObb.value = true;
    };

    const resetField = () => {
      alertCampiObb.value = false;
      spliceTesserati();
      spliceSocieta();
      selectedStagioni.value = [];
      nome_persona.value = "";
      nome_societa.value = "";
      id_persona.value = null;
      id_societa.value = null;
      id_stagioni.value = "";
    };

    const inputElement = (selected) => {
      id_stagioni.value = selected.join(",");
    };

    const focus = ref("");

    return {
      focus,
      stagioniOptions,
      nome_persona,
      nome_societa,
      id_persona,
      id_societa,
      getTesserati,
      getSocieta,
      resetTesseratiList,
      resetSocieta,
      spliceTesserati,
      spliceSocieta,
      tesseratiList: computed(() => store.getters.personeSocieta),
      societa: computed(() => store.getters.societaNomeCodAff),
      schema,
      onSubmit,
      onInvalidSubmit,
      resetField,
      alertCampiObb,
      selectedStagioni,
      id_stagioni,
      inputElement,
      disableButton,
      isLoading,
    };
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
