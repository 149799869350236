import services from "../axios/dbManag";

export const addVivaio = (vivaio) => {
  return services.dbManag
    .post("/tesseramento/vivaio/add", {
      ...vivaio,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const editVivaio = (vivaio) => {
  return services.dbManag
    .post("/tesseramento/vivaio/edit", {
      ...vivaio,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
